// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.splash {
  align-items: center;
  background-color: #2B9595; }
  .splash .splash-container {
    max-width: 90vw;
    text-align: center;
    width: 360px; }
    .splash .splash-container a {
      color: white; }
    .splash .splash-container button {
      background: #2B9595;
      border: 1px solid white;
      color: white;
      font-family: Lexend, sans-serif;
      font-size: 1rem;
      font-weight: bold;
      height: 40px;
      padding: 0 1.5rem;
      text-transform: uppercase; }
      .splash .splash-container button:active, .splash .splash-container button:hover {
        background: white;
        color: #2B9595; }
    .splash .splash-container img {
      margin-bottom: 3rem;
      width: 100%; }
    .splash .splash-container input[type="text"] {
      border: none;
      display: block;
      font-family: Lexend, sans-serif;
      font-size: 22px;
      font-weight: 500;
      height: 40px;
      margin-bottom: 1.5rem;
      text-align: center;
      width: 100%; }
    .splash .splash-container p {
      color: white;
      font-family: Lexend, sans-serif;
      font-size: 22px;
      font-weight: 500;
      line-height: 2rem;
      margin-bottom: 1.5rem; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/Splash.scss"],"names":[],"mappings":"AAEA;EACE,mBAAmB;EACnB,yBAAyB,EAAA;EAF3B;IAKI,eAAe;IACf,kBAAkB;IAClB,YAAY,EAAA;IAPhB;MAUM,YAAY,EAAA;IAVlB;MAcM,mBAAmB;MACnB,uBAAuB;MACvB,YAAY;MACZ,+BAA+B;MAC/B,eAAe;MACf,iBAAiB;MACjB,YAAY;MACZ,iBAAiB;MACjB,yBAAyB,EAAA;MAtB/B;QAyBQ,iBAAiB;QACjB,cAAc,EAAA;IA1BtB;MA+BM,mBAAmB;MACnB,WAAW,EAAA;IAhCjB;MAoCM,YAAY;MACZ,cAAc;MACd,+BAA+B;MAC/B,eAAe;MACf,gBAAgB;MAChB,YAAY;MACZ,qBAAqB;MACrB,kBAAkB;MAClB,WAAW,EAAA;IA5CjB;MAgDM,YAAY;MACZ,+BAA+B;MAC/B,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,qBAAqB,EAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');\n\n.splash {\n  align-items: center;\n  background-color: #2B9595;\n\n  .splash-container {\n    max-width: 90vw;\n    text-align: center;\n    width: 360px;\n\n    a {\n      color: white;\n    }\n\n    button {\n      background: #2B9595;\n      border: 1px solid white;\n      color: white;\n      font-family: Lexend, sans-serif;\n      font-size: 1rem;\n      font-weight: bold;\n      height: 40px;\n      padding: 0 1.5rem;\n      text-transform: uppercase;\n\n      &:active, &:hover {\n        background: white;\n        color: #2B9595;\n      }\n    }\n\n    img {\n      margin-bottom: 3rem;\n      width: 100%;\n    }\n\n    input[type=\"text\"] {\n      border: none;\n      display: block;\n      font-family: Lexend, sans-serif;\n      font-size: 22px;\n      font-weight: 500;\n      height: 40px;\n      margin-bottom: 1.5rem;\n      text-align: center;\n      width: 100%;\n    }\n\n    p {\n      color: white;\n      font-family: Lexend, sans-serif;\n      font-size: 22px;\n      font-weight: 500;\n      line-height: 2rem;\n      margin-bottom: 1.5rem;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
