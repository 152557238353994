// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0; }
  .audio-control button {
    height: 48px;
    width: 48px;
    background-size: contain;
    background-color: transparent;
    border: 0; }
    .audio-control button.end {
      background-image: var(--button-end); }
      .audio-control button.end:active, .audio-control button.end:hover {
        background-image: var(--button-end-active); }
    .audio-control button.talk {
      background-image: var(--button-talk-wait); }
  .audio-control.recording .talk {
    background-image: var(--button-talk-active); }
  .audio-control.processing .talk {
    background-image: var(--button-talk-wait); }
  .audio-control.listening .talk {
    background-image: var(--button-talk); }

.kb-container .audio-control {
  flex-grow: 0 !important;
  margin: 0 !important;
  padding: 0 !important; }
  .kb-container .audio-control .talk {
    background: none;
    height: 100%;
    inset: 0;
    position: fixed;
    width: 100%;
    z-index: 1000; }
  .kb-container .audio-control .end {
    display: none; }
`, "",{"version":3,"sources":["webpack://./src/components/stylesheets/AudioControl.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,SAAS,EAAA;EAJX;IAOI,YAAY;IACZ,WAAW;IACX,wBAAwB;IACxB,6BAA6B;IAC7B,SAAS,EAAA;IAXb;MAcM,mCAAmC,EAAA;MAdzC;QAiBQ,0CAA0C,EAAA;IAjBlD;MAqBM,yCAAyC,EAAA;EArB/C;IA2BM,2CAA2C,EAAA;EA3BjD;IAgCM,yCAAyC,EAAA;EAhC/C;IAqCM,oCAAoC,EAAA;;AAK1C;EAEI,uBAAuB;EACvB,oBAAoB;EACpB,qBAAqB,EAAA;EAJzB;IAOM,gBAAgB;IAChB,YAAY;IACZ,QAAQ;IACR,eAAe;IACf,WAAW;IACX,aAAa,EAAA;EAZnB;IAgBM,aAAa,EAAA","sourcesContent":[".audio-control {\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  bottom: 0;\n\n  button {\n    height: 48px;\n    width: 48px;\n    background-size: contain;\n    background-color: transparent;\n    border: 0;\n\n    &.end {\n      background-image: var(--button-end);\n\n      &:active, &:hover {\n        background-image: var(--button-end-active);\n      }\n    }\n    &.talk {\n      background-image: var(--button-talk-wait);\n    }\n  }\n\n  &.recording {\n    .talk {\n      background-image: var(--button-talk-active);\n    }\n  }\n  &.processing {\n    .talk {\n      background-image: var(--button-talk-wait);\n    }\n  }\n  &.listening {\n    .talk {\n      background-image: var(--button-talk);\n    }\n  }\n}\n\n.kb-container {\n  .audio-control {\n    flex-grow: 0 !important;\n    margin: 0 !important;\n    padding: 0 !important;\n\n    .talk {\n      background: none;\n      height: 100%;\n      inset: 0;\n      position: fixed;\n      width: 100%;\n      z-index: 1000;\n    }\n\n    .end {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
